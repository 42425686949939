import React, { createContext, useContext, useState } from "react"

const defaultContext = {
  isOpen: false,
}

export const Context = createContext(defaultContext)
export const useAuth = () => useContext(Context)

const ProviderWrapper = ({ children }) => {
  const [isOpen, openCart] = useState(false)

  return (
    <Context.Provider value={{ isOpen, openCart }}>{children}</Context.Provider>
  )
}

export function useAuthValue() {
  return useContext(Context)
}

export default ProviderWrapper
