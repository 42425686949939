import React from "react"
import "./src/style/style.scss"
import browserHydrateFunction from './src/utils/gatsby/browser-hydrate-function';

import ProviderWrapper from "./src/context"
import { CartProvider } from "use-shopping-cart"


export const replaceHydrateFunction = browserHydrateFunction;

export const wrapRootElement = ({ element }) => (
  <ProviderWrapper>
    <CartProvider
      mode="payment"
      cartMode="checkout-session"
      stripe={process.env.GATSBY_STRIPE_PUBLISHABLE_KEY}
      currency="GBP"
    >
      {element}
    </CartProvider>
  </ProviderWrapper>
)
